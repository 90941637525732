import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import styled from 'styled-components';

import SEO from '../components/SEO';
import Layout from '../components/Layout';
import { HeroContainer } from '../components/Containers';
import { TextBlock, TextBlockDescription } from '../components/Text';
import { StyledBackgroundImage } from '../components/Image';
import { Collage } from '../components/Image';
import EmbedContainer from '../components/EmbedContainer';

const StrapLine = styled.span`
    font-family: 'Yeseva One';
    font-size: '1.1em';
    display: inline-block;
`;

const List = styled.ol`
    list-style: none;
    padding: 0;
    margin: 0;

    > li {
        border-top: 1px solid #000000;
        padding: 0.5em 0;
        :last-child {
            border-bottom: 1px solid #000000;
        }
    }
`;

const ListContainer = styled.div`
    :nth-child(odd) {
        > h3 {
            color: green;
        }
    }
    :nth-child(even) {
        > h3 {
            color: red;
        }
    }
`;

export const AboutPageTemplate = ({
    title,
    image,
    subheading,
    listHeading,
    styledLists,
    firstSection,
    secondSection,
    collage
}) => {
    return (
        <div>
            <HeroContainer
                title={title}
                image={image}
                subheading={subheading}
            />
            {firstSection && (
                <section className="section section--gradient">
                    <div className="container">
                        <div className="columns">
                            <TextBlock color="green" className="column is-6">
                                <h2 className="is-size-1">
                                    {firstSection.textPanel.title}
                                </h2>
                                <TextBlockDescription>
                                    {firstSection.textPanel.description}
                                </TextBlockDescription>
                                <p>{firstSection.textPanel.about}</p>
                            </TextBlock>
                            <Img
                                className="column is-6"
                                fluid={
                                    firstSection.image.childImageSharp
                                        ? firstSection.image.childImageSharp
                                              .fluid
                                        : { src: firstSection.image }
                                }
                            />
                        </div>
                    </div>
                </section>
            )}
            <section className="section section--gradient">
                <div className="container">
                    <div className="columns is-centered">
                        <div className="column is-10">
                            <h2
                                className="is-size-4"
                                style={{ textAlign: 'center' }}
                            >
                                {listHeading}
                            </h2>
                        </div>
                    </div>
                    <div className="columns">
                        {styledLists.map(list => (
                            <ListContainer
                                key={list.action}
                                className="column is-4"
                            >
                                <StrapLine className="is-size-5">
                                    {list.strapLine}
                                </StrapLine>
                                <h3 className="is-size-2">{list.action}</h3>
                                <List>
                                    {list.items.map(item => (
                                        <li key={item.item}>{item.item}</li>
                                    ))}
                                </List>
                            </ListContainer>
                        ))}
                    </div>
                </div>
            </section>
            {secondSection && (
                <section className="section section--gradient">
                    <div className="container">
                        <div className="columns">
                            <Img
                                className="column is-6"
                                fluid={
                                    secondSection.imageThree.childImageSharp
                                        ? secondSection.imageThree.childImageSharp
                                              .fluid
                                        : { src: secondSection.imageThree }
                                }
                            />
                            <TextBlock color="red" className="column is-6">
                                <TextBlockDescription>
                                    {secondSection.textPanel.description}
                                </TextBlockDescription>
                                <p>{secondSection.textPanel.about}</p>
                            </TextBlock>
                        </div>
                        <div className="columns" style={{ height: '600px' }}>
                            <Img
                                className="column is-4"
                                fluid={
                                    secondSection.imageOne.childImageSharp
                                        ? secondSection.imageOne.childImageSharp
                                              .fluid
                                        : { src: secondSection.imageOne }
                                }
                            />
                            <Img
                                className="column is-8"
                                fluid={
                                    secondSection.imageTwo.childImageSharp
                                        ? secondSection.imageTwo.childImageSharp
                                              .fluid
                                        : { src: secondSection.imageTwo }
                                }
                            />
                        </div>
                    </div>
                </section>
            )}
            {/* <section className="section section--gradient">
                <div className="container">
                    <EmbedContainer>
                        <iframe
                            src="https://www.youtube.com/embed/<NEW VIDEO COMING>"
                            frameBorder="0"
                            allowFullScreen
                            title="Follow Your Senses In Tuscany - YouTube"
                        ></iframe>
                    </EmbedContainer>
                </div>
            </section> */}
            <section className="section section--gradient">
                <div className="container">
                    <Collage collage={collage} />
                </div>
            </section>
        </div>
    );
};

AboutPageTemplate.propTypes = {
    title: PropTypes.string.isRequired,
    content: PropTypes.string,
    contentComponent: PropTypes.func
};

const AboutPage = ({ data }) => {
    const { markdownRemark: post } = data;

    return (
        <Layout>
            <SEO
                title={post.frontmatter.title}
                image={post.frontmatter.image.childImageSharp.fluid.src}
                description={post.frontmatter.description}
            />
            <AboutPageTemplate
                title={post.frontmatter.title}
                image={post.frontmatter.image}
                subheading={post.frontmatter.subheading}
                mainpitch={post.frontmatter.mainpitch}
                styledLists={post.frontmatter.styledLists}
                firstSection={post.frontmatter.firstSection}
                secondSection={post.frontmatter.secondSection}
                listHeading={post.frontmatter.listHeading}
                collage={post.frontmatter.collage}
            />
        </Layout>
    );
};

AboutPage.propTypes = {
    data: PropTypes.object.isRequired
};

export default AboutPage;

export const aboutPageQuery = graphql`
    query AboutPage($id: String!) {
        markdownRemark(id: { eq: $id }) {
            html
            frontmatter {
                title
                image {
                    childImageSharp {
                        fluid(maxWidth: 2048, quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
                collage {
                    image {
                        childImageSharp {
                            fluid(maxWidth: 2048, quality: 100) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
                subheading
                mainpitch {
                    title
                    description
                }
                firstSection {
                    image {
                        childImageSharp {
                            fluid(maxWidth: 1024, quality: 100) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                    textPanel {
                        title
                        description
                        about
                    }
                }
                secondSection {
                    imageOne {
                        childImageSharp {
                            fluid(maxWidth: 1024, quality: 100) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                    imageTwo {
                        childImageSharp {
                            fluid(maxWidth: 1024, quality: 100) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                    imageThree {
                        childImageSharp {
                            fluid(maxWidth: 1024, quality: 100) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                    textPanel {
                        description
                        about
                    }
                }
                listHeading
                styledLists {
                    strapLine
                    action
                    items {
                        item
                    }
                }
                blurbs {
                    image {
                        childImageSharp {
                            fluid(maxWidth: 2048, quality: 100) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
            }
        }
    }
`;
